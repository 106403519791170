<template>
    <ul
        role="list"
        class="-mx-2 space-y-1"
    >
        <li>
            <nuxt-link
                href="/"
                class="nav-item"
                active-class="active"
            >
                <HomeIconOutline class="icon" />
                Dashboard
            </nuxt-link>
        </li>
        <li v-if="accessAvailability">
            <nuxt-link
                href="/availability"
                class="nav-item"
                active-class="active"
            >
                <ClockIconOutline class="icon" />
                Disponibilités
            </nuxt-link>
        </li>

        <li v-if="accessRegistrations">
            <UiDropdown
                :opened="[
                    '/registrations'
                ].some(p => $route.path.includes(p))"
            >
                <template #trigger="{ open }">
                    <button
                        class="nav-item w-full"
                        :class="{ active: open }"
                    >
                        <BookOpenIconOutline class="icon" />
                        Inscriptions
                        <ChevronRightIconMini
                            class="self-center ml-auto transition-all size-5"
                            :class="{
                                'transform rotate-90': open,
                                'transform rotate-0': !open
                            }"
                        />
                    </button>
                </template>
                <template #content>
                    <div>
                        <nuxt-link
                            :to="$creche.type === 'aes' || $creche.type === 'stage' ? '/registrations/aes/create' : '/registrations/create'"
                            class="nav-item sub-item pl-10"
                            active-class="active"
                        >
                            Nouvelle inscription
                        </nuxt-link>
                        <nuxt-link
                            to="/registrations"
                            class="nav-item sub-item pl-10"
                            active-class="active"
                        >
                            Demandes en attente
                        </nuxt-link>
                    </div>
                </template>
            </UiDropdown>
        </li>

        <li>
            <UiDropdown
                :opened="[
                    '/babies',
                    '/parents',
                    '/classrooms',
                    '/babies/archived'
                ].some(p => $route.path.includes(p))"
            >
                <template #trigger="{ open }">
                    <button
                        class="nav-item w-full"
                        :class="{ active: open }"
                    >
                        <Icon
                            name="lucide:baby"
                            class="icon stroke15"
                        />
                        Enfants
                        <ChevronRightIconMini
                            class="self-center ml-auto transition-all size-5"
                            :class="{
                                'transform rotate-90': open,
                                'transform rotate-0': !open
                            }"
                        />
                    </button>
                </template>
                <template #content>
                    <div>
                        <nuxt-link
                            to="/babies"
                            class="nav-item sub-item pl-10"
                            active-class="active"
                        >
                            Fiches des enfants
                        </nuxt-link>
                        <nuxt-link
                            to="/parents"
                            class="nav-item sub-item pl-10"
                            active-class="active"
                        >
                            Fiches des parents
                        </nuxt-link>
                        <nuxt-link
                            to="/classrooms"
                            class="nav-item sub-item pl-10"
                            active-class="active"
                        >
                            Sections
                        </nuxt-link>
                        <nuxt-link
                            to="/babies/archived"
                            class="nav-item sub-item pl-10"
                            active-class="active"
                        >
                            Archives
                        </nuxt-link>
                    </div>
                </template>
            </UiDropdown>
        </li>

        <li v-if="accessAttendances">
            <UiDropdown
                :opened="[
                    '/attendances',
                    '/activity',
                    '/events'
                ].some(p => $route.path.includes(p))"
            >
                <template #trigger="{ open }">
                    <button
                        class="nav-item w-full"
                        :class="{ active: open }"
                    >
                        <CalendarDaysIconOutline class="icon" />
                        Calendrier
                        <ChevronRightIconMini
                            class="self-center ml-auto transition-all size-5"
                            :class="{
                                'transform rotate-90': open,
                                'transform rotate-0': !open
                            }"
                        />
                    </button>
                </template>
                <template #content>
                    <div>
                        <nuxt-link
                            to="/attendances"
                            class="nav-item sub-item pl-10"
                            active-class="active"
                        >
                            Présences
                        </nuxt-link>
                        <nuxt-link
                            to="/activity"
                            class="nav-item sub-item pl-10"
                            active-class="active"
                        >
                            Activités journalières
                        </nuxt-link>
                        <nuxt-link
                            to="/events"
                            class="nav-item sub-item pl-10"
                            active-class="active"
                        >
                            Évènements
                        </nuxt-link>
                        <nuxt-link
                            to="/demands"
                            class="nav-item sub-item pl-10 justify-between"
                            active-class="active"
                        >
                            Demandes

                            <span
                                v-if="pendingDemands"
                                class="bg-primary-600 text-white mr-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full"
                            >
                                {{ pendingDemands }}
                            </span>
                        </nuxt-link>
                    </div>
                </template>
            </UiDropdown>
        </li>

        <li v-if="accessStaff">
            <UiDropdown
                :opened="[
                    '/staff',
                    '/staff/calendar'
                ].some(p => $route.path.includes(p))"
            >
                <template #trigger="{ open }">
                    <button
                        class="nav-item w-full"
                        :class="{ active: open }"
                    >
                        <Icon
                            name="streamline:office-worker"
                            class="stroke09"
                        />
                        Personnel
                        <ChevronRightIconMini
                            class="self-center ml-auto transition-all size-5"
                            :class="{
                                'transform rotate-90': open,
                                'transform rotate-0': !open
                            }"
                        />
                    </button>
                </template>
                <template #content>
                    <div>
                        <nuxt-link
                            to="/staff"
                            class="nav-item sub-item pl-10"
                            active-class="active"
                        >
                            Fiches du personnel
                        </nuxt-link>
                        <nuxt-link
                            to="/staff/calendar"
                            class="nav-item sub-item pl-10"
                            active-class="active"
                        >
                            Horaires
                        </nuxt-link>
                    </div>
                </template>
            </UiDropdown>
        </li>

        <li v-if="accessBilling">
            <UiDropdown
                :opened="[
                    '/invoices',
                    '/payments'
                ].some(p => $route.path.includes(p))"
            >
                <template #trigger="{ open }">
                    <button
                        class="nav-item w-full"
                        :class="{ active: open }"
                    >
                        <CurrencyEuroIconOutline class="icon" />
                        Facturation
                        <ChevronRightIconMini
                            class="self-center ml-auto transition-all size-5"
                            :class="{
                                'transform rotate-90': open,
                                'transform rotate-0': !open
                            }"
                        />
                    </button>
                </template>
                <template #content>
                    <div>
                        <nuxt-link
                            to="/invoices"
                            class="nav-item pl-10 sub-item"
                            active-class="active"
                        >
                            Factures
                        </nuxt-link>
                        <nuxt-link
                            to="/payments"
                            class="nav-item pl-10 sub-item"
                            active-class="active"
                        >
                            Paiements
                        </nuxt-link>
                        <nuxt-link
                            to="/payments/balances"
                            class="nav-item pl-10 sub-item"
                            active-class="active"
                        >
                            Soldes Clients
                        </nuxt-link>
                        <nuxt-link
                            to="/invoices/forecast"
                            class="nav-item pl-10 sub-item"
                            active-class="active"
                        >
                            Prévisions
                        </nuxt-link>
                    </div>
                </template>
            </UiDropdown>
        </li>

        <li v-if="accessCommunications">
            <UiDropdown
                :opened="[
                    '/communications'
                ].some(p => $route.path.includes(p))"
            >
                <template #trigger="{ open }">
                    <button
                        class="nav-item w-full"
                        :class="{ active: open }"
                    >
                        <ChatBubbleLeftEllipsisIconOutline class="icon" />
                        Communications
                        <ChevronRightIconMini
                            class="self-center ml-auto transition-all size-5"
                            :class="{
                                'transform rotate-90': open,
                                'transform rotate-0': !open
                            }"
                        />
                    </button>
                </template>
                <template #content>
                    <div>
                        <nuxt-link
                            to="/communications/emails"
                            class="nav-item sub-item pl-10"
                            active-class="active"
                        >
                            Emails
                        </nuxt-link>
                        <nuxt-link
                            to="/communications/sms"
                            class="nav-item sub-item pl-10"
                            active-class="active"
                        >
                            SMS
                        </nuxt-link>
                        <nuxt-link
                            to="/communications/social"
                            class="nav-item sub-item pl-10"
                            active-class="active"
                        >
                            Publications
                        </nuxt-link>
                    </div>
                </template>
            </UiDropdown>
        </li>

        <li v-if="accessMedical && $creche.type !== 'aes'">
            <nuxt-link
                href="/medicalvisits"
                class="nav-item"
                active-class="active"
            >
                <Icon
                    class="icon"
                    name="ph:heartbeat"
                />
                Visites Médicales
            </nuxt-link>
        </li>

        <li v-if="accessSettings">
            <UiDropdown
                :opened="[
                    '/settings',
                    '/settings/creche',
                    '/settings/finance',
                    '/settings/documents',
                    '/settings/schedule'
                ].some(p => $route.path.includes(p))"
            >
                <template #trigger="{ open }">
                    <button
                        class="nav-item w-full"
                        :class="{ active: open }"
                    >
                        <AdjustmentsHorizontalIconOutline class="icon" />
                        Paramètres
                        <ChevronRightIconMini
                            class="self-center ml-auto transition-all size-5"
                            :class="{
                                'transform rotate-90': open,
                                'transform rotate-0': !open
                            }"
                        />
                    </button>
                </template>
                <template #content>
                    <div>
                        <nuxt-link
                            to="/settings/creche"
                            class="nav-item pl-10 sub-item"
                            active-class="active"
                        >
                            Milieu d'accueil
                        </nuxt-link>
                        <nuxt-link
                            to="/settings/finance"
                            class="nav-item pl-10 sub-item"
                            active-class="active"
                        >
                            Facturation
                        </nuxt-link>
                        <nuxt-link
                            to="/settings/documents"
                            class="nav-item pl-10 sub-item"
                            active-class="active"
                        >
                            Documents
                        </nuxt-link>
                        <nuxt-link
                            to="/settings/schedule"
                            class="nav-item pl-10 sub-item"
                            active-class="active"
                        >
                            Horaires et Fermetures
                        </nuxt-link>
                        <nuxt-link
                            to="/settings/doctors"
                            class="nav-item pl-10 sub-item"
                            active-class="active"
                        >
                            Médecins
                        </nuxt-link>
                        <nuxt-link
                            to="/settings/mailtemplates"
                            class="nav-item pl-10 sub-item"
                            active-class="active"
                        >
                            Emails Types
                        </nuxt-link>
                    </div>
                </template>
            </UiDropdown>
        </li>

        <li v-if="accessReports">
            <UiDropdown
                :opened="[
                    '/reporting',
                    '/reporting/one',
                    '/reporting/remunerations',
                    '/reporting/fiscaldocuments',
                    '/stats'
                ].some(p => $route.path.includes(p))"
            >
                <template #trigger="{ open }">
                    <button
                        class="nav-item w-full"
                        :class="{ active: open }"
                    >
                        <PresentationChartLineIconOutline class="icon" />
                        Rapports
                        <ChevronRightIconMini
                            class="self-center ml-auto transition-all size-5"
                            :class="{
                                'transform rotate-90': open,
                                'transform rotate-0': !open
                            }"
                        />
                    </button>
                </template>
                <template #content>
                    <div>
                        <nuxt-link
                            to="/reporting/one"
                            class="nav-item pl-10 sub-item"
                            active-class="active"
                        >
                            Documents ONE
                        </nuxt-link>
                        <nuxt-link
                            to="/reporting/pro-one"
                            class="nav-item pl-10 sub-item"
                            active-class="active"
                        >
                            Rapport PRO ONE
                        </nuxt-link>
                        <nuxt-link
                            to="/reporting/fiscaldocuments"
                            class="nav-item pl-10 sub-item"
                            active-class="active"
                        >
                            Attestations fiscales
                        </nuxt-link>
                        <nuxt-link
                            to="/reporting/remunerations"
                            class="nav-item pl-10 sub-item"
                            active-class="active"
                        >
                            Prix journaliers
                        </nuxt-link>
                        <nuxt-link
                            to="/stats"
                            class="nav-item pl-10 sub-item"
                            active-class="active"
                        >
                            Statistiques
                        </nuxt-link>
                    </div>
                </template>
            </UiDropdown>
        </li>
    </ul>
</template>

<script>
import { loadingMixin } from 'abcreche-ui'
import permissionsMixin from '~/mixins/permissionsMixin.js'
import DemandRepository from '~/repositories/Creche/DemandRepository.js'

export default {
    mixins: [permissionsMixin, loadingMixin],

    data () {
        return {
            pendingDemands: 0
        }
    },

    created () {
        this.getPendingDemandsCount()

        this.emitter.on('demands-refresh-count', () => {
            this.getPendingDemandsCount()
        })
    },

    methods: {
        getPendingDemandsCount () {
            return DemandRepository.countPending()
                .then((response) => {
                    this.pendingDemands = response.total_pending
                })
                .catch(error => this.pageError(error))
        }
    }
}
</script>
